.aboutContainer {
  min-height: 100vh;
  min-width: 106.4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 3rem;
  margin-bottom: 1rem;
}

.about {
  display: flex;
  align-items: center;
  width: 70%;
  padding: 1rem;
  gap: 2rem;
}

.about p {
  height: auto;
}

.about img {
  width: 70%;
  height: 20rem;
  object-fit: contain;
}

.textWriter {
  font-size: calc(30% * 4vw);
}

.imgContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 0 13px;
}

.imgContainer img {
  width: 70%;
  height: 130px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.programHeader {
  text-align: center;
  width: 100%;
  background-color: #36acd6;
  color: white;
  padding: 1rem;
}

.videoContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  place-content: center;
}

.videoContainer video {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 300px;
}
@media (max-width: 812px) {
  .imgSlide {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 700px) {
  .about {
    flex-direction: column;
  }
  .about img {
    object-fit: fill;
    width: 80%;
    height: 25rem;
  }
}

@media (max-width: 450px) {
  .about {
    width: 95%;
  }

  .infoHeading {
    text-align: center;
    font-size: 99%;
  }
}
