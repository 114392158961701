.footer {
  width: 106.4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #36acd6;
  line-height: 2.1rem;
  text-align: center;
}

.footer a {
  text-decoration: none;
  background-color: #2e1814;
  color: #f6fcfe;
  font-weight: 700;
  padding: 0.6%;
  border-radius: 5px;
  width: 16%;
  min-width: 10rem;
  text-align: center;
}

.footer p {
  color: #57271e;
}

.footer p span {
  font-weight: 800;
}
