.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.serviceContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: center;
  width: 100%;
  gap: 1rem;
}

.card {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 16px;
  text-align: center;
  transition: box-shadow 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
}
.card p {
  font-size: 1.2rem;
  font-weight: 800;
}

.installationHeader {
  text-align: center;
  background-color: #36acd6;
  color: white;
  padding: 1rem;
  width: 106.4%;
}

@media (max-width: 860px) {
  .serviceContainer {
    grid-template-columns: 80%;
  }
  .card {
    flex-direction: column;
  }

  .card img {
    object-fit: cover;
  }
}
