.heroImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.imageContainer {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.page {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  margin-bottom: 1rem;
}

.page .header {
  width: 79%;
  font-size: calc(0.6em + 1vw);
  line-height: 1.2;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  word-wrap: break-word;
  top: 12px;
}

.page small {
  display: block;
  text-align: center;
  color: red;
  font-weight: 600;
  font-size: 67%;
}

.page .image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.page .image img {
  width: 70%;
  height: auto;
  max-height: 100vh;
  object-fit: contain;
}

.page p img {
  width: 2em;
  height: 2em;
}

.page .locationText {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(1rem + 0.5vw);
  color: #34495e;
  text-align: center;
  padding: 15px;
  background: linear-gradient(135deg, #ecf0f1, #bdc3c7);
  border-radius: 12px;
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page .locationText span {
  color: #2980b9;
}

.page .locationText em {
  color: #e67e22;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.sectionContainer img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.sectionContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding-left: 10px;
}

.sectionContainer img:nth-child(4) {
  grid-column-start: 1;
}

.sectionContainer img:nth-child(5) {
  grid-column-start: 3;
}
