.headerLogo {
  display: inline-block;
  height: 40px;
  width: auto;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 1rem 0;
  padding: 1rem;
}

.headerTitle {
  display: flex;
  align-items: center;
  color: #2e1814;
}
.links {
  display: flex;
}
.links a {
  color: #2e1814;
  margin-left: 20%;
}
a {
  display: inline-block;
  text-decoration: none;
}
.links a:hover {
  text-decoration: underline;
  font-weight: 600;
}

.book {
  border-radius: 10px;
  background: #36acd6;
  color: #f6fcfe;
  font-family: "General Sans";
  font-size: 80%;
  font-weight: 800;
  text-align: center;
  padding: 1.4em;
}

.book:hover {
  scale: 1.04;
  transition: 500ms ease;
}

@media (max-width: 800px) {
  .navbar h5 {
    display: none;
  }

  .book {
    width: 40%;
    font-weight: 700;
  }
}

@media (max-width: 440px) {
  .navbar {
    padding: 0.5%;
  }
  .links {
    position: absolute;
    left: 20%;
  }
  .book {
    position: absolute;
    right: 1%;
    width: 33%;
    font-size: 55%;
  }
}
