* {
  margin: 0;
  padding: 0;
  user-select: none;

  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif; /* Modern, clean font */
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  overflow-x: hidden;
}
img {
  pointer-events: none;
}

h1,
h2,
h3 {
  font-family: "Roboto", sans-serif; /* Bold, clean heading font */
  color: #333;
  font-weight: 700;
}

h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

h3 {
  font-size: 22px;
  margin-bottom: 10px;
}
